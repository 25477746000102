import {
    DISCONNECT,
    SET_ADDRESS,
    SET_BALANCE,
    SET_LOADING,
    SET_MESSAGE,
    SET_SIGNATURE,
    START_WALLET_CONNECTION,
    WALLET_CONNECTION_FAILED,
    WALLET_CONNECTION_SUCCEEDED
} from "../utils/actionConstants"

const initialState = {
    connection_started: false,
    address: null,
    signature: null,
    message: null,
    balance: 0,
    connected: false,
    loading: false
}

function authReducer(state = initialState, action) {
    switch (action.type) {
        case START_WALLET_CONNECTION:
            return {
                ...state,
                connection_started: true
            }
        case WALLET_CONNECTION_SUCCEEDED:
            return {
                ...state,
                connected: true
            }
        case WALLET_CONNECTION_FAILED:
            return {
                ...state,
                connected: false,
                error: action.payload.error
            }
        case SET_ADDRESS:
            return {
                ...state,
                address: action.payload.address,
                connected: true
            }
        case SET_SIGNATURE:
            return {
                ...state,
                signature: action.payload.signature
            }
        case SET_BALANCE:
            return {
                ...state,
                balance: action.payload.balance
            }
        case SET_MESSAGE:
            return {
                ...state,
                message: action.payload.message
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload.loading
            }
        case DISCONNECT:
            return {
                ...initialState
            }
        default:
            return state
    }
}

export default authReducer
